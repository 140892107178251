.rounded-button {
    $button-height: 45px;

    display: inline-block;
    min-width: 210px;
    width: auto;
    height: $button-height;
    padding: 0px 30px;
    border-radius: 28px;
    border: solid 1px #ffffff;
    cursor: pointer;

    font-family: $font-primary;
    color: #ffffff!important;
    text-align: center;
    font-weight: 700;
    line-height: $button-height;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover, &:active {
        text-decoration: none;
    }

}

.arrow-button {
    padding: 0px 30px;

    &:after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 10px;
        margin-top: 15px;
        position: relative;
        @include rotate (45);
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transition: all ease-in .1s;
    }

    &:hover {
        &:after {
            margin-left: 14px;
        }
    }

}

.blue-btn {
    background-color: $blue-sella;
    color: #fff;

    &:hover {
        background-color: #2a5aea;

    }
}

.light-blue-btn {
    background-color: $light-blue;
    color: #fff;
}

.arrow-link {
    width: 209px;
    height: 21px;
    font-family: Sella;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #339aef;

}

.doublearrow-link {
    width: 61px;
    height: 16px;
    font-family: Sella;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0033cc;
}
