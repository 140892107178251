@font-face {
    font-family: "Sella";
    src:url("#{$fontPath}Sella-Regular.eot");
    src:url("#{$fontPath}Sella-Regular.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}Sella-Regular.woff") format("woff"),
    url("#{$fontPath}Sella-Regular.woff2") format("woff2"),
    url("#{$fontPath}Sella-Regular.ttf") format("truetype"),
    url("#{$fontPath}Sella-Regular.svg#sella") format("svg");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Sella";
    src:url("#{$fontPath}Sella-Medium.eot");
    src:url("#{$fontPath}Sella-Medium.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}Sella-Medium.woff") format("woff"),
    url("#{$fontPath}Sella-Medium.woff2") format("woff2"),
    url("#{$fontPath}Sella-Medium.ttf") format("truetype"),
    url("#{$fontPath}Sella-Medium.svg#sella") format("svg");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Sella";
    src:url("#{$fontPath}Sella-Bold.eot");
    src:url("#{$fontPath}Sella-Bold.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}Sella-Bold.woff") format("woff"),
    url("#{$fontPath}Sella-Bold.woff2") format("woff2"),
    url("#{$fontPath}Sella-Bold.ttf") format("truetype"),
    url("#{$fontPath}Sella-Bold.svg#sella") format("svg");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "SellaStencil";
    src:url("#{$fontPath}SellaStencil-MediumWEB.eot");
    src:url("#{$fontPath}SellaStencil-MediumWEB.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}SellaStencil-MediumWEB.woff") format("woff"),
    url("#{$fontPath}SellaStencil-MediumWEB.woff2") format("woff2"),
    url("#{$fontPath}SellaStencil-MediumWEB.ttf") format("truetype"),
    url("#{$fontPath}SellaStencil-MediumWEB.svg#sella") format("svg");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "SellaStencil";
    src:url("#{$fontPath}SellaStencil-BoldWEB.eot");
    src:url("#{$fontPath}SellaStencil-BoldWEB.eot?#iefix") format("embedded-opentype"),
    url("#{$fontPath}SellaStencil-BoldWEB.woff") format("woff"),
    url("#{$fontPath}SellaStencil-BoldWEB.woff2") format("woff2"),
    url("#{$fontPath}SellaStencil-BoldWEB.ttf") format("truetype"),
    url("#{$fontPath}SellaStencil-BoldWEB.svg#sella") format("svg");
    font-weight: 700;
    font-style: normal;
}

$font-primary: "Sella", sans-serif;
$font-secondary: "SellaStencil", sans-serif;

body * {
    font-family: $font-primary;
}

.text-title, .text-title, h1, h2, h3, h4, h5, h6 {
    font-family: $font-secondary;
}

.text-title *, h1 *, h2 *, h3 *, h4 *, h5 *, h6 * {
    font-family: $font-secondary;
}

.text-blue-gradient {
    font-family: $font-primary;
    color: $blue-sella;
}
html.chrome .text-blue-gradient {
    font-family: $font-primary;
    color: $blue-sella;
    background-image: $text-blue-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

html.ie .text-blue-gradient {
    background-image: none;
}

.font-primary, .font-primary * {
    font-family: $font-primary!important;
}
.font-secondary, .font-secondary * {
    font-family: $font-secondary!important;
}

.text-light-blue {
    color: $light-blue;
}
