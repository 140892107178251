#content {
    overflow: hidden;
    height: auto;
}

.portlet-header {
    display: none;
}

.dialog-iframe-root-node {
    height: 2000px;
    overflow: auto;
}

#wrapper.container-fluid {
    padding: 0;
}

.portlet-content {
    padding: 0!important;
    border-width: 0!important;
    border: 0!important;

    p {
        margin-bottom: 0;
    }
}

.portlet {
    margin: 0;
}


body.staging {
    #banner {
        .navbar {
            margin-top: 130px;
            z-index: 1;
        }
    }
}

body.has-control-menu {
    #banner {
        #joinsellagroup-navbar {
            // margin-top: 70px!important;
            top: 55px!important;
            z-index: 10!important;
        }
    }
}



.staging-top{
    position: absolute;
    top: 0;
    left: 40%;
    border-bottom: none!important;
    background-color: transparent!important;
    height: 100%!important;

    .portlet-staging-bar,
    .navigation-bar-secondary{
        height: 100%!important;
    }
    .navbar-nav .nav-link{
        box-shadow: none!important;
    }
    .staging-bar{
        display: none;
    }
}
