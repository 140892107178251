/* Table of breakpoints and content:
 *
 *
 * 0 - General
 *  0.1 - ...
 *  0.2 - lib overrides
 *
 *
 * 1 - Mobile First
 *  1.1 - Small devices (landscape phones, 576px and up)
 *  1.2 - Medium devices (tablets, 768px and up)
 *  1.3 - Large devices (desktops, 992px and up)
 *  1.4 - Extra large devices (large desktops, 1200px and up)
 *
 *
 * 2 - Desktop First
 *  2.1 Extra small devices (portrait phones, less than 576px)
 *  2.2 Small devices (landscape phones, less than 768px)
 *  2.3 Medium devices (tablets, less than 992px)
 *  2.4 Large devices (desktops, less than 1200px)
 *
 *
 * 3 - Specific breakpoint
 *  3.1 Small devices (form 576px to 767.98px)
 *  3.2 Medium devices (from 768px to 991.98px)
 *  3.3 Large devices (from 992px to 1199.98px)
 *  3.4 Extra large devices (from 1200px to 1439.98px)
 *
 *
 *
*/






//4 - General

body {
    position: relative;
    height: 100%;
    overflow-y: scroll;
}

.container-lg {
    max-width: 1200px!important;
    height: auto;
    overflow: hidden;
}


#banner {
    // position: fixed;
    // width: 100%;
    //margin-bottom: 60px;

    .navbar {

        // .fixed-top.open-dropdown {
        //     padding-bottom: 100%;
        // }

        transition: all ease-in .3s;
        background-color: $blue-sella;
        background-color: #fff;




        .navbar-brand {
            width: 70px;
            height: 25px;
            margin-top: 10px;
            margin-bottom: 10px;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('#{$logosPath}logo-blu@2x.png');
            background-size: contain;
        }

        .navbar-toggler {
            outline: none;

            .navbar-toggler-icon {
                background-image: url('#{$iconsPath}close-icon-blue.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.collapsed {
                .navbar-toggler-icon {
                    background-image: url('#{$iconsPath}burge-menu-icon-blue.png');

                }
            }

        }



        #navbarNavV2 {
            //float: right;
            .navbar-nav {
                padding-top: 50px;
                flex-direction: column;

                .nav-item {
                    margin: 0;
                    text-align: left;

                    .nav-link {
                        font-family: $font-primary;
                        font-size: 19px;
                        font-weight: 500;
                        color: $blue-sella;


                        &.active-nav-link {
                            font-weight: bold;
                        }

                    }

                    &.active {
                        .nav-link {

                        }
                    }

                    .dropdown-menu {
                        border-radius: 0;
                        padding: 5px;
                        background-color: transparent;
                        border: 0;

                        .dropdown-item {
                            color: $blue-sella;
                            text-align: left;
                            font-size: 19px;
                            font-weight: 600;
                            padding: .5rem 1.5rem;
                            transition: all ease-out .2s;
                        }

                    }

                }

                .pipe-separator {
                    display: inline-block;
                    &:before {
                        content: "|";
                        display: inline-block;
                        margin: 0 10px;
                        color: $blue-sella;
                        font-size: 12px;

                    }
                }

            }
        }

        &.navbar-sticky {
            box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.14);
        }

        &.navbar-blue-theme:not(.navbar-sticky) {
            background-color: transparent;

            &.open-dropdown {
                background-color: $blue-sella;
            }

            .navbar-brand {
                background-image: url('#{$logosPath}logo-bianco@2x.png');
            }

            .navbar-toggler {
                .navbar-toggler-icon {
                    background-image: url('#{$iconsPath}close-icon.png');
                }

                &.collapsed {
                    .navbar-toggler-icon {
                        background-image: url('#{$iconsPath}burge-menu-icon.png');
                    }
                }
            }



            #navbarNavV2 {
                //float: right;
                .navbar-nav {

                    .nav-item {

                        .nav-link {
                            color: #fff;

                        }

                        &.active {
                            .nav-link {

                            }
                        }

                        .dropdown-menu {
                            .dropdown-item {
                                //color: #fff;
                            }

                        }

                    }

                    .pipe-separator {
                        &:before {
                            color: #fff;
                        }
                    }

                }
            }
        }

    }


}

@media screen and (max-width:991px) {

    #banner .navbar.open-dropdown.navbar-blue-theme:not(.navbar-sticky) #navbarNavV2 .navbar-nav .nav-item .dropdown-menu .dropdown-item {
        color: #fff;
    }

    .navbar.open-dropdown {
        padding-bottom: 50px;
    }

}

.image-section {
    padding: 120px 0;
    min-height: 336px;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .text-topic {
        margin-bottom: 20px;
        font-family: $font-secondary;
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
    }

    .text-claim {
        font-weight: bold;
        color: #ffffff;
    }
    h1.text-claim {
        font-size: 53px;
    }

    h2.text-claim {
        font-size: 45px;
        margin-bottom: 20px;
        line-height: normal;
    }


    .text-subtitle {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.04;
        color: #ffffff;
    }

    .text-paragraph {
        opacity: 0.75;
        font-family: $font-primary;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.24;
        color: #ffffff;
    }

}

.simple-section {
    padding: 90px 0;

    &.bg-blue-gradient {
        background-image: $bg-blue-gradient;
        color: #fff;
    }


    &.top-overlay-offset {
        position: relative;
        top: -110px;
        padding-top: 0px;
    }

    h2.text-title {
        font-family: $font-secondary;
        font-size: 55px;
        font-weight: 600;

        @media screen and (max-width: 767px) {
            font-size: 37px;
        }

    }

    h3.text-title {
        font-family: $font-secondary;
        font-size: 23px;
        font-weight: 500;
        line-height: 1.05;
        margin-bottom: 45px;
    }

    .title-posizioni {
        font-family: $font-primary!important;
        font-size: 33px!important;
    }

    // h3.text-title {
    //     font-family: $font-secondary;
    //     font-size: 69px;
    //     font-weight: bold;
    //
    //     .text-title-note {
    //         display: block;
    //         font-family: $font-primary;
    //         font-size: 16px;
    //         font-weight: bold;
    //         color: $dark-grey;
    //     }
    //
    //     &.blue {
    //         color: $blue-sella;
    //     }
    // }

    h4.text-subtitle {
        font-family: $font-secondary;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.25;
        margin-top: 20px;
    }



    &.wave {

        .container-lg {
            position: relative;
            z-index: 1
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 200px;
            z-index: 0;
            background-repeat: no-repeat;
            background-position: center top;
            background-size: cover;
            background-image: url('#{$bgPath}onda@2x.png');
            margin-top: -70px;
        }
    }

}



.simple-text-container {
    padding: 120px 0;

    header {
        margin-bottom: 40px;

        .text-title {
            font-family: $font-secondary;
            font-weight: bold;
            color: $light-blue;
            margin-bottom: 14px;
        }
        h1.text-title {
            font-size: 50px;
        }
        h2.text-title {
            font-size: 50px;
            font-weight: bold;
        }

        .text-subtitle {
            color: $blue-sella;
            margin-bottom: 25px;
        }
        h3.text-subtitle {
            font-size: 30px;
            font-weight: bold;
            line-height: 1.1;
        }
        h4.text-subtitle {
            font-size: 30px;
            line-height: 1.08;
            font-weight: bold;
        }

        .text-note, .text-note p {
            font-family: $font-primary;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.2;
            color: #828282;

        }
    }

    .video-box {
        height: 25vw;
        background-color: #afafaf;
        margin-bottom: 30px;
    }
    .image-box {
        // height: 25vw;
        // background-color: #afafaf;
        margin-bottom: 30px;
    }
    .text-paragraph {
        margin-bottom: 20px;
        font-family: $font-primary;
        font-size: 16px;
        line-height: 1.6;
        color: $super-dark-grey;

        .text-light-blue, h5.text-title.text-light-blue {
            font-size: 23px;
        }


        img {
            margin: 2em 0;
        }
    }

}


.iconplus-section {
    padding: 90px 0;

    &.bg-blue-gradient {
        background-image: $bg-blue-gradient;
        color: #fff;
    }

    .text-title {
        font-size: 45px!important;
        margin-bottom: 70px!important;
    }
}

.numbers {

    .text-subtitle {
        font-family: SellaStencil;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        color: $super-dark-grey;
    }

    .number-item {
        margin: 20px 0 35px;
        box-sizing: border-box;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 7px;
            margin-bottom: 30px;
            background: rgb(51,154,239);
            background: linear-gradient(80deg, rgba(51,154,239,1) 0%, rgba(134,200,255,1) 100%);
        }

        .number-title, .number-title * {
            font-size: 39px;
            font-weight: 500;
            letter-spacing: -1.6px;
            font-family: $font-secondary;
            color: $super-dark-grey;
        }

        .number-title {
            display: inline-block;

            .odometer {


            }

            .odometer-decimal {
                .odometer-value {
                    font-size: 40px !important;
                }

                &:before {
                    content: ",";
                    display: inline-block;
                    font-size: 40px;
                }
            }

            .text-smaller {
                font-family: $font-secondary;
                font-size: 28px;
                letter-spacing: normal;
                display: block;
            }

            .apex {
                font-size: 0.7em;
                position: relative;
                top: -0.7em;
                left: -0.2em;
            }

        }

        .number-note, .number-note p {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            color: $super-dark-grey;
            display: block;
            margin-top: 15px;

            .number-note-small, .number-note-small p {
                display: block;
                margin-top: 15px;
                font-size: 13px;
                line-height: 1.23;
            }
        }
    }

    .arrow-link {
        display: inline-block;
        margin-top: 50px;
        width: auto;

        &:after {
            content: "";
            display: inline-block;
            width: 25px;
            height: 25px;
            position: relative;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYhe2WzU4TURSAvzO307AQacuPrmTnxpRQJ0XRhTOJSxe+gpqq1ZfwGYyQlNT0IVxBazFxpaBUjRoxIoSopMYOEl0Ic+e6aqoG0cV0uum3vSf3fDn35JwLffr0GIn6QmfOLwN5JXLxcSH1/l/xVtQCYkgDE9qYxVzZH49dQP0IL4F5BIxbIQ+cUuvYQfGRPwGAU2oNIbIATGFYs+zQXbo8vBmbQFvCiFQF8sC7QOtzz4ojH2ITAJis+Cm1Rw1wgLehCtyVK6MfYxMAyM5up5PK3EfIAauYwH1ybfRT+zzyJvyTFzdSflJZ54EGcByx6/mZ5tH2edcr0Gbqzs6wTuo6MAHyek8r93lxsBmbAIBT2hlBdB3IAi/DRMITZ86/B1yIU+QXHloikuhRcgwoAXBvmVgkWke+ZWwV1IBsuw9i64Hp8tfMrg5rCDmMeWMFgbt0c2xLxZE8O7udRkwN4SSwCtpbLo5tAXRdYLLip5JClc409J4WOoOoqwJOqTUkoVTp7AO3cfX3Udy1SXjq9pfDCPMCeQxrViL09ltGXanA2bufB3dVYgE4DayDcZcL+6/jyAVOzDQPGbHnBaaBDa3xVq5nNv4WH/kTDNh2ReAMhk0l4jWK6fWD4qPvAeE78ArE+59PaZ8+Pecn5Q6kiTF0Kn0AAAAASUVORK5CYII=');
            background-size: contain;
            background-repeat: no-repeat;
            top: 4px;
            margin-left: 10px;

        }
    }

}

.section-blue-gradient {
    background-image: $bg-blue-gradient;
    padding: 40px 0;
    color: #fff;

    h3.text-title {
        font-family: $font-primary;
        font-size: 36px;
        padding: 20px 0;
        font-weight: bold;
        text-align: left;
        color: #ffffff;

        > span {
            font-weight: normal;
        }
    }
}


.text-slider {
    margin: 0;
    padding: 20px 0;

    .text-slider-item {
        h4.text-title {
            font-family: $font-primary;
            font-size: 32px;
            font-weight: 500;

            small {
                font-size: 20px;
            }

        }

        .text-paragraph {
            opacity: 0.75;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.21;
        }

    }



}



.persona-items-list {

    .persona-item {
        background-color: #fff;
        box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.09);
        margin-bottom: 120px;


        .persona-item-img {
            width: 100%;
            height: 100%;
            display: block;
            min-height: 390px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        .persona-item-info {
            padding: 45px 90px 45px 45px;
            padding: 30px 20px;


            .persona-item-topic {
                display: block;
                margin-bottom: 20px;
                font-family: $font-primary;
                font-size: 18px;
                font-weight: bold;
                color: $grey;
            }

            .persona-item-title {
                display: block;
                font-family: $font-secondary;
                font-size: 34px;
                font-weight: 500;
                color: #339aef;
                line-height: 1.2;
                text-decoration: none!important;
            }

            h3.persona-item-title {
                font-size: 47px;
                color: $blue-sella;
                margin-bottom: 20px;
            }
            h6.persona-item-subtitle {
                font-size: 24px;
                font-weight: normal;
                font-family: $font-primary;
                color: $super-dark-grey;
            }

            .persona-item-subtitle {
                font-family: $font-primary;
                font-size: 26px;
                font-weight: bold;
                color: $blue-sella;
                line-height: 1.2;
                text-decoration: none!important;
            }

            .persona-item-text {
                font-family: $font-primary;
                font-size: 15px;
                line-height: 1.47;
                color: $super-dark-grey;
            }

            .persona-item-cta {
                display: inline-block;
                float: left;
                position: relative;
                top: 20px;
                height: 49px;
                line-height: 49px;
                transition: all ease-out .3s;

                &:hover {
                    background-color: #2a5aea;

                }
            }

        }

        &:nth-child(even) {
            .persona-item-info-box {
                // -ms-display: flex;
                // flex: 1;
                // -ms-flex-order: 0;
                // order: 0;

                .persona-item-info {

                    .persona-item-cta {
                    }

                }

            }

            .persona-item-img-box {
                // -ms-display: flex;
                // flex: 1;
                // -ms-flex-order: 1;
                // order: 1;
            }
        }


        &.single-block {
            .persona-item-info {
                padding: 30px 30px;

                h3.text-title.persona-item-title.text-center {
                    font-size: 39px;
                }

                .persona-item-cta {
                    top: 40px;
                }
            }

        }

    }

}


.joinad-items-list {

    .joinad-item {
        margin-bottom: 40px;
        padding: 30px 40px;
        box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.09);
        background-color: #fff;

        .joinad-item-title {
            font-family: $font-primary;
            color: $blue-sella;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 5px;

            .joinad-item-title-note {
                display: block;
                font-family: $font-primary;
                color: $dark-grey;
                font-size: 14px;
                text-transform: none;

            }


        }

        .joinad-item-location {
            margin-top: 5px;
            display: block;
            color: #000;
            font-size: 18px;
            text-transform: capitalize;

            span {
                text-transform: capitalize;
            }

            &:before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 4px;
                position: relative;
                top: 2px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("#{$iconsPath}location-dark-grey.png");
            }

        }

        .joinad-item-cta {

        }

    }
}

.joinad-item-title-note, .search-result-title-note {
    display: none!important;
}

html.chrome {
    .joinad-item-title-note, .search-result-title-note {
        display: block!important;
    }
}


.search-section {
    margin: 4rem 0;

    .search-form {

        .search-form-label {
            font-family: $font-primary;
            font-size: 13px;
            font-weight: 500;
            color: #000000;
            text-transform: uppercase;
        }

        .search-form-field {
            background-color: #efefef;
            border: 0;
            border-radius: 0;
        }

    }

    .search-results-list {
        margin: 2em 0;

        .search-results-list-label {
            padding: 0 0 1.2em;
            border-bottom: 1px solid $light-grey;

            text-transform: uppercase;
            font-family: $font-primary;
            font-size: 16px;
            font-weight: bold;
            color: $dark-grey;
        }

        .search-results-wrapper {
            transition: all ease-out .2s;
        }

        .search-result {
            padding: 2em 0;
            border-bottom: 1px solid $light-grey;

            .search-result-title {
                font-family: $font-primary;
                color: $blue-sella;
                font-size: 21px;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 5px;

                .search-result-title-note {
                    display: block;
                    font-family: $font-primary;
                    color: $dark-grey;
                    font-size: 14px;
                    text-transform: none;

                }
            }

            .search-result-location, .search-result-company {
                margin-top: 5px;
                display: block;
                color: #000;
                font-size: 15px;
                padding: 0 0 0 1.4em;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    position: absolute;
                    margin-top: 2px;
                    float: left;
                    margin-left: -1.4em;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
            .search-result-location:before {
                background-image: url("#{$iconsPath}location-light-grey.png");
            }
            .search-result-company:before {
                background-image: url("#{$iconsPath}company.png");
            }

            .search-result-location, .search-result-location span {
                text-transform: capitalize;
            }

            .search-result-cta {
                min-width: 160px;
            }

        }
    }
}


.search-header-section {
    margin-top: 50px;
    padding: 4rem 0 3rem;

    &.bg-blue-gradient {
        background-image: $bg-blue-gradient;
        color: #fff;
    }

    .search-title {
        padding: 0 1em .2em 0;

        //border-bottom: 1px solid rgba(255,255,255,.44);

        font-size: 38px;
        font-weight: bold;
        color: #fff;

        // &:after {
        //     content: "";
        //     display: inline-block;
        //     width: .8em;
        //     height: .8em;
        //     float: right;
        //     position: relative;
        //     top: 0.4em;;
        //     right: -1em;
        //
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     background-image: url('#{$iconsPath}search.png');
        // }
    }

}

.iconplus-item {
    margin-bottom: 70px;

    .iconplus-item-img {
        width: 120px;
        height: 120px;
//        background-color: #fff;
        margin: 0 auto 20px;
        text-align: center;

        img {
            max-width: 80%;
        }
    }

    .iconplus-item-title {
        font-family: $font-secondary;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
    }

    .iconplus-item-text {
        padding: 0 30px;

        opacity: 0.75;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.31;
        text-align: center;
        color: #ffffff;
    }
}


.light-blue-banner {
    background-color: #40a7e3;
    color: #fff;
    padding: 15px 0 15px;

    .text-title {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        margin: 5px 0;

        > p {
            margin-bottom: 0;
        }
    }

    .linkedin-logo {
        display: block;
        height: 30px;
        margin-bottom: 30px;
        background-position: left top;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("#{$logosPath}LinkedIn-logo@2x.png");
    }
}


.img-slider {
    $height: 200px;

    height: $height;
    background: #fff;

    .img-slider-item {
        height: $height;
        width: 100%;

        .img-slider-item-bg {
            height: $height;
            width: 100%;

            background-position: left center;
            background-repeat: no-repeat;
            background-size: cover;

            &.img-slider-item-bg-mobile {

            }

        }
    }
}




#hero {
    background-color: $blue-sella;
    padding: 200px 0 75px;

    .arrow-button {
        margin-top: 60px;
    }
}


#logo-section {
    .logo-slider {

        .logo-slider-item {
            display: block;
            margin: 20px 20px 40px 0;
            width: 180px;
            max-width: 100%;
            height: 50px;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .owl-dots {
            margin-top: 30px;
        }

    }

}


#profile-section {
    .item-with-top-line-wrapper {
        display: block;
        text-decoration: none;
        color: inherit;

        .item-with-top-line {
            display: block;
            margin-top: 50px;
            margin-right: 30px;
            box-sizing: border-box;
            cursor: pointer;

            .top-line {
                display: block;
                $top-line-height: 4px;

                height: $top-line-height;
                background: $light-grey;
                overflow: hidden;
                display: block;
                width: 100%;
                margin-bottom: 20px;

                &:before {
                    content: "";
                    display: block;
                    width: 0;
                    height: $top-line-height;
                    position: relative;
                    background-color: $blue-sella;
                    transition: all ease-out .3s;
                }
            }

            &:hover {
                .top-line:before {
                    width: 100%;
                }
            }


            .text-title {
                display: block;
                font-size: 28px;
                font-weight: 500;
                color: $super-dark-grey;
            }

            .text-paragraph {
                display: block;
                margin-top: 14px;
                font-size: 14px;
                line-height: 1.29;
                color: $super-dark-grey;
            }
            .doublearrow-link {
                display: block;
                margin-top: 11px;
                display: block;
            }

        }
    }

}

#footer {

    $top-space: 35px;

    padding: 0 0 35px;
    background-image: $bg-blue-gradient;

    .sella-logo {
        display: block;
        height: 40px;
        width: 100%;
        margin-top: $top-space;
        background-position: left top;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("#{$logosPath}logo-bianco@2x.png");
    }

    .text-paragraph, .text-paragraph p {
        margin-top: $top-space;
        font-size: 12px;
        color: #ffffff;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    .social-container {
        display: block;
        list-style: none;
        width: 100%;
        padding-inline-start: 0px;
        margin-top: 35px;

        .social-item {
            display: inline-block;
            max-width: 30%;
            width: 30px;
            height: 30px;

            .social-logo {
                display: block;
                width: 80%;
                height: 100%;
                margin: auto;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
            }

        }

    }

}


//0.2 - lib overrides
.owl-theme {
    .owl-dots {
        //margin-top: 50px!important;

        .owl-dot {
            > span {
                background: $blue-sella!important;
                opacity: .5;
                width: 8px;
                height: 8px;

            }

            &.active {
                > span {
                    width: 12px;
                    height: 13px;
                    opacity: 1;
                    position: relative;
                    top: 2px;
                }
            }
        }

    }

    &.owl-theme-white {
        .owl-dots {

            .owl-dot {
                > span {
                    background: #fff!important;
                }
            }

        }
    }
}



$bg-s32: url(#{$bgPath}s32.jpg);
.bg-s32 {
    background-image: $bg-s32;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-s32-desktop {
    background-image: none;
}

.bg-s32-mobile {
    background-image: none;
}






// 1.1 Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    #hero {
        padding: 200px 0 75px;

        .arrow-button {
            margin-top: 100px;
        }

    }
}

// 1.2 Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .persona-items-list {

        .persona-item {

            .persona-item-img {
                min-height: 200px;
            }
            .persona-item-info {

                .persona-item-topic {
                }

                .persona-item-title {
                }

                h3.persona-item-title {
                }
                h6.persona-item-subtitle {
                }

                .persona-item-subtitle {
                    font-size: 34px;
                }

                .persona-item-text {
                }

                .persona-item-cta {
                    float: right;
                }

            }

            &:nth-child(even) {
                .persona-item-info-box {
                    // -ms-display: flex;
                    // flex: 1;
                    // -ms-flex-order: 0;
                    // order: 0;

                    .persona-item-info {

                        .persona-item-cta {
                            float: left;
                        }

                    }

                }

                .persona-item-img-box {

                }
            }



        }

    }
}

// 1.3 Large devices (desktops, 992px and up)
@media (min-width: 992px) {

    #banner {

        .navbar {
            .navbar-brand {
                width: 100px;
                height: 35px;
            }


            #navbarNavV2 {
                //float: right;
                .navbar-nav {
                    padding-top: 0px;
                    flex-direction: row;

                    .nav-item {
                        padding-left: .5rem;
                        padding-right: .5rem;

                        .nav-link {
                            font-size: 13px;
                            &.active-nav-link {
                            }

                        }

                        &.active {
                            .nav-link {

                            }
                        }

                        .dropdown-menu {
                            background-color: #fff;
                            box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.14);
                            .dropdown-item {
                                color: $blue-sella;
                                text-align: left;
                                font-size: 12px;

                                &:active {
                                    color: $blue-sella;
                                    background-color: #fff;
                                }

                            }

                        }

                    }

                    .pipe-separator {

                    }

                }
            }


            // &.navbar-blue-theme {
            //     #navbarNavV2 {
            //         //float: right;
            //         .navbar-nav {
            //
            //             .nav-item {
            //
            //                 .nav-link {
            //
            //                     &.active-nav-link {
            //                     }
            //
            //                 }
            //
            //                 &.active {
            //                     .nav-link {
            //
            //                     }
            //                 }
            //
            //                 .dropdown-menu {
            //                     background-color: $blue-sella;
            //
            //                     .dropdown-item {
            //                         color: #fff;
            //                     }
            //
            //                 }
            //
            //             }
            //
            //             .pipe-separator {
            //
            //             }
            //
            //         }
            //     }
            // }

        }

    }

    .image-section {
        padding: 200px 0;

        .text-topic {
            font-size: 27px;
        }

        .text-claim {
        }
        h1.text-claim {
            font-size: 98px;
        }

        h2.text-claim {
            font-size: 73px;
        }


        .text-subtitle {
            font-size: 47px;
        }

        .text-paragraph {
            font-size: 25px;
        }

    }

    .simple-section {
        padding: 110px 0;

        h3.text-title {
            margin-bottom: 75px;
        }

        h3.text-title.text-blue-gradient {
            font-size: 44px;
            font-weight: 600;
        }

    }


    .section-blue-gradient {

        h3.text-title {
            text-align: right;

        }
    }


    .numbers {


        .text-subtitle {
            font-size: 24px;
        }

        .number-item {
            margin: 40px 20px 40px 0;

            &:before {
            }

            // .number-title, .number-title * {
            //     font-size: 55px;
            // }

            .number-title {
                display: inline-block;

                .odometer {

                    .odometer-decimal-value {
                        .odometer-value {
                            font-size: 48px!important;
                        }
                    }

                }

                .odometer-decimal {
                    .odometer-value {
                        // position: relative;
                        // top: 4px;
                    }

                    &:before {
                        margin-right: -8px;
                    }
                }

                .text-smaller {
                    //font-size: 32px;
                    margin-top: -12px;
                }

            }

            .number-note {
                font-size: 16px;
                margin-top: 8px;

                > p {
                    margin-top: 0px;
                }

                small {
                    font-size: 13px;
                }
            }
        }

        .arrow-link {
            display: inline-block;
            margin-top: 70px;



        }
    }

    .text-slider {
        margin: 0 0 0 30px;
        padding: 20px 0 20px 30px;
        border-left: 1px solid rgba(255, 255, 255, .6);

        .text-slider-item {
            h4.text-title {
                small {
                }

            }

            .text-paragraph {
            }

        }

    }


    .simple-text-container {

        header {
            margin-bottom: 70px;

            .text-title {
            }
            h1.text-title {
                font-size: 80px;
            }
            h2.text-title {
                font-size: 60px;
            }

            .text-subtitle {
            }
            h3.text-subtitle {
                font-size: 60px;
            }
            h4.text-subtitle {
                font-size: 40px;
            }

            p.text-note {
                font-size: 20px;
            }
        }

        .video-box {
            height: 25vw;
            background-color: #afafaf;
            margin-bottom: 30px;
        }
        .image-box {
            height: 25vw;
            background-color: #afafaf;
            margin-bottom: 30px;
        }
        .text-paragraph {
            margin-bottom: 20px;
            font-family: $font-primary;
            font-size: 16px;
            line-height: 1.6;
            color: $super-dark-grey;
        }



    }

    .light-blue-banner {

        .linkedin-logo {
            background-position: right center;
            margin-bottom: 0;
        }
    }

    .img-slider {
        $height: 335px;
        height: $height;

        .img-slider-item {
            height: $height;

            .img-slider-item-bg {
                height: $height;
            }
        }
    }

    .search-header-section {
        margin-top: 50px;
        padding: 4rem 0 3rem;

        &.bg-blue-gradient {
            background-image: $bg-blue-gradient;
            color: #fff;
        }

        .search-title {
            font-size: 48px;

            // &:after {
            //     content: "";
            //     display: inline-block;
            //     width: .8em;
            //     height: .8em;
            //     float: right;
            //     position: relative;
            //     top: 0.4em;;
            //     right: -1em;
            //
            //     background-position: center;
            //     background-repeat: no-repeat;
            //     background-size: contain;
            //     background-image: url('#{$iconsPath}search.png');
            // }
        }

    }


    .search-section {

        .search-results-list {
            margin: 4em 0;

            .search-result {
                padding: 1.4em 0;
            }
        }
    }

    .bg-s32-desktop {
        background-image: $bg-s32;
        background-position: 49vw center;
    }



    .persona-items-list {

        .persona-item {

            .persona-item-info {
                padding: 45px 90px 45px 45px;
            }


            &.single-block {
                .persona-item-info {
                    padding: 60px 90px 20px;

                    h3.text-title.persona-item-title.text-center {
                        font-size: 47px;
                    }

                }

            }

        }

    }


}


// 1.4 Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

    .bg-arrow-left.bg-arrow-right {
        background-image: url(#{$bgPath}bg-arrow-left@2x.png), url(#{$bgPath}bg-arrow-right@2x.png);
        background-position: left 10%, right 90%;
        background-repeat: no-repeat, no-repeat;
        background-size: 30vw, 30vw;
    }

    .bg-arrow-left {
        background-image: url(#{$bgPath}bg-arrow-left@2x.png);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 230px;
    }

    .bg-arrow-right {
        background-image: url(#{$bgPath}bg-arrow-right@2x.png);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 15%;
    }

    .numbers {


        .text-subtitle {
            font-size: 24px;
        }

        .number-item {
            //margin-bottom: 130px;

            &:before {
            }

            .number-title, .number-title * {
                font-size: 55px;
            }
        }
        .number-title {

            .text-smaller {
                font-size: 32px;
            }

        }
    }
}




// 2.1 Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// 2.2 Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

}

// 2.3 Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .bg-s32.bg-s32-desktop {
        padding-bottom: 0px;
    }
    .bg-s32-mobile {
        background-image: $bg-s32;
    }
}

// 2.4 Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}




// 3.1 Small devices (form 576px to 767.98px)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// 3.2 Medium devices (from 768px to 991.98px)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// 3.3 Large devices (from 992px to 1199.98px)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// 3.4 Extra large devices (from 1200px to 1439.98px)
@media (min-width: 1200px) and (max-width: 1439.98px) {

}
