//colors
$light-blue: #40a7e3;
$blue-sella: #0033cc;

$bg-blue-gradient: linear-gradient(to right, #3467ff, #0033cc 50%);
$bg-light-blue-gradient: linear-gradient(to left, #86c8ff 102%, #339aef 50%);
$text-blue-gradient: linear-gradient(93deg, #5b84ff 27%, #0033cc 61%);

$light-grey: #d4d4d4;
$grey: #9a9a9a;
$dark-grey: #6d6d6d;
$super-dark-grey: #333333;


$imagePath: "../images/";
$iconsPath: "#{$imagePath}icons/";
$logosPath: "#{$imagePath}logos/";
$bgPath: "#{$imagePath}bg/";

$fontPath: "../fonts/";


$imagePath: "../images/";
$iconsPath: "#{$imagePath}icons/";
$logosPath: "#{$imagePath}logos/";
$bgPath: "#{$imagePath}bg/";

$fontPath: "../fonts/";
