.custom-modal{

    &.modal{
        display:none;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modal-dialog {
        margin: .5rem;
        position: relative;
    }

    .modal-header{
        border-bottom:0;
        height: auto;
        padding-bottom: 1rem;
    }
    .modal-title{
        font-size:30px;
        line-height: 36px;
        white-space:normal;
    }

    .modal-content{
        padding: 40px 10px 10px;
    }

    .close{
        opacity: 0.3!important;
        box-shadow: none!important;
        font-size:1.8rem;
        font-weight: 400;
        position: absolute;
        top:15px;
        right: 15px;
    }

    @media screen and (min-width: 576px) {

        .modal-dialog {
            margin: 1.75rem auto;
            max-width: 95%;
        }
        .modal-content{
            padding:50px 40px 10px;
        }
    }

    @media screen and (min-width: 768px) {
        .modal-dialog {
            max-width: 90%;
        }
        .close{
            top:25px;
            right: 25px;
        }

    }

    @media screen and (min-width: 992px) {
        .modal-lg { max-width: 900px; }
        .modal-content{
            padding:70px 70px 50px
        }
    }

    @media screen and (min-width: 1200px) {
        .modal-lg { max-width: 1100px; }
    }
}
